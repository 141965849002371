<rt-box
  [class.active]="rewardsVisible && application.referralReward"
  (click)="selectCandidate(application)"
  class="grid-container"
  variant="no-padding no-radius"
  padding-small
>
  <div class="referral">
    <rt-avatar
      [type]="application.photoThumbUrl ? 'image' : application.name ? 'initials' : 'icon'"
      [shape]="'oval'"
      [size]="'base'"
      [imageSrc]="application.photoThumbUrl"
      [fullName]="application.name"
      [iconName]="$any(!application.name && 'person')"
      margin-right-small
    ></rt-avatar>
    <div layout-vertical>
      @if (application.name) {
        <rt-heading variant="heading">
          {{ application.name }}
        </rt-heading>
      } @else {
        @if (!application.name) {
          <span text-bold>
            {{ 'rtr.profile.applications.removed_candidate' | translate }}
          </span>
        }
      }

      <div layout layout-wrap>
        @if (type === ReferralContext.Referrals) {
          <span margin-right-small>{{ application.offerTitle }}</span>
        }
        <div layout>
          <rt-icon
            [name]="
              showStatus && application.referralStatus
                ? statusTranslationsMap[application.referralStatus].icon
                : statusTranslationsMap[ReferralStatus.InReview].icon
            "
            margin-right-small-xx
          ></rt-icon>
          <span>
            {{
              showStatus && application.referralStatus
                ? statusTranslationsMap[application.referralStatus].status
                : statusTranslationsMap[ReferralStatus.InReview].status
            }}
            {{ (showStatus ? application.updatedAt : application.createdAt) | rtTimeAgo }}</span
          >
        </div>
      </div>
    </div>
  </div>
  @if (application.referralReward && rewardsVisible && type === ReferralContext.Referrals) {
    <div
      [rtTooltip]="
        (showStatus && application.referralStatus === ReferralStatus.Claimed
          ? 'rtr.reward.tooltip.success'
          : 'rtr.reward.tooltip.default'
        ) | translate
      "
      [rtTooltipHasTextAlignCenter]="true"
      [rtTooltipMaxWidth]="320"
      class="reward"
    >
      <rt-icon
        [colorToken]="
          !showStatus || application.referralStatus !== ReferralStatus.Claimed
            ? 'colorIconDefault'
            : 'colorIconLowDefault'
        "
        name="reward"
        margin-right-small-x
      ></rt-icon>
      <span
        [class.text-bold]="showStatus && application.referralStatus === ReferralStatus.Claimed"
        >{{ application.referralReward.name }}</span
      >
    </div>
  }
  @if (showStatus && application.referralStatus && type === ReferralContext.Offer) {
    <div
      [rtTooltip]="offerTooltipTranslations[application.referralStatus]"
      [rtTooltipHasTextAlignCenter]="true"
      [rtTooltipMaxWidth]="320"
      class="reward"
    >
      @if (application.referralStatus === ReferralStatus.InReview) {
        <span>{{ 'rtr.referrals.in_progress' | translate }}</span>
      }
      @if (application.referralStatus === ReferralStatus.ProcessComplete) {
        <span>{{ 'rtr.referrals.completed' | translate }}</span>
      }
      @if (application.referralStatus === ReferralStatus.Claimed) {
        <span text-bold> {{ 'rtr.referrals.statuses.successful_referral' | translate }}</span>
      }
    </div>
  }
</rt-box>
