import { HttpErrorResponse } from '@angular/common/http';

import { createSelector } from '@ngrx/store';

import {
  ActionScopedPayload,
  createEntityCollectionStore,
  DEFAULT_SCOPE_NAME,
  pipeState,
  Scope,
} from '@recruitee/ngrx';
import {
  ReferralsPortalApplicationStatus,
  ReferralsPortalApplicationClaimStatus,
} from '@recruitee/referrals-types';
import type {
  ReferralsPortalApplicationParams,
  ReferralsPortalApplication,
  ReferralsPortalApplicationPayloadI,
} from '@recruitee/referrals-types';

import { EntityStoreKey } from '../types';

const resolveApplicationStatus = (app: ReferralsPortalApplication) => {
  if (
    app.status === ReferralsPortalApplicationStatus.ProcessComplete ||
    (app.status === ReferralsPortalApplicationStatus.Claimed &&
      app.claimStatus !== ReferralsPortalApplicationClaimStatus.Accepted)
  )
    return ReferralsPortalApplicationStatus.ProcessComplete;

  if (
    app.status === ReferralsPortalApplicationStatus.Claimed &&
    app.claimStatus === ReferralsPortalApplicationClaimStatus.Accepted
  )
    return ReferralsPortalApplicationStatus.Claimed;

  return ReferralsPortalApplicationStatus.InReview;
};

const { actions, selectors, reducer, namespace } =
  createEntityCollectionStore<ReferralsPortalApplication>()(EntityStoreKey.applications, {
    customActions: adapter => ({
      fetchCollection: (
        state,
        payload: { data: ReferralsPortalApplicationParams; scope?: Scope },
      ) =>
        adapter.markAsPending(state, { scope: payload?.scope || DEFAULT_SCOPE_NAME, entities: [] }),
      fetchCollectionSuccess: (
        state,
        { data, scope }: ActionScopedPayload<ReferralsPortalApplication[]>,
      ) =>
        pipeState(
          state,
          next => adapter.setMany(next, data, { scope }),
          next =>
            adapter.markAsLoaded(next, {
              scope: scope || DEFAULT_SCOPE_NAME,
              entities: data.map(e => e.id),
            }),
        ),
      fetchOfferCollection: (
        state,
        payload: { data: ReferralsPortalApplicationParams; scope: Scope },
      ) => adapter.markAsPending(state, { scope: payload.scope, entities: [] }),
      submitApplication: (state, payload: ReferralsPortalApplicationPayloadI) => state,
      submitApplicationSuccess: (state, payload: any) => state,
      submitApplicationFail: (
        state,
        payload: { error: HttpErrorResponse & { errorFields: any } },
      ) => state,
    }),
  });

const mappedEntities = (scope?: string) =>
  createSelector(
    selectors.entities({ scope: scope || null }),
    (state: ReferralsPortalApplication[]) =>
      state.map(app => ({
        ...app,
        referralStatus: resolveApplicationStatus(app),
      })),
  );

const referralSelectors = { ...selectors, mappedEntities };

export {
  actions as ApplicationsActions,
  reducer as applicationsReducer,
  referralSelectors as applicationsSelectors,
  namespace as ApplicationsNamespace,
};
