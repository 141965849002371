import { ChangeDetectorRef, Component, OnDestroy } from '@angular/core';

import { Subject } from 'rxjs';

import { TranslatePipe } from '@recruitee/i18n';
import {
  ReferralsPortalApplication,
  ReferralsPortalApplicationStatus,
} from '@recruitee/referrals-types';
import {
  DialogComponentI,
  DialogRef,
  IconName,
  TimeAgoPipe,
  BoxComponent,
  DialogBodyComponent,
  IconComponent,
  IconColorDirective,
  HeadingComponent,
  AvatarComponent,
  DialogHeaderComponent,
} from '@recruitee/user-interface';

type ReferralsDialogData = {
  referral: ReferralsPortalApplication;
  translations: Record<string, { status: string; icon: IconName }>;
  showStatus: boolean;
};

@Component({
  selector: 'rtr-referrals-dialog',
  templateUrl: './referrals-dialog.component.html',
  styleUrls: ['./referrals-dialog.component.less'],
  standalone: true,
  imports: [
    DialogHeaderComponent,
    AvatarComponent,
    HeadingComponent,
    IconColorDirective,
    IconComponent,
    DialogBodyComponent,
    BoxComponent,
    TimeAgoPipe,
    TranslatePipe,
  ],
})
export class ReferralsDialogComponent
  implements DialogComponentI<ReferralsDialogData, boolean>, OnDestroy
{
  public referral: ReferralsPortalApplication;
  public statusTranslationsMap: Record<string, { status: string; icon: IconName }>;
  public showStatus: boolean;

  public readonly ReferralStatus: typeof ReferralsPortalApplicationStatus =
    ReferralsPortalApplicationStatus;

  public ngOnDestroy$ = new Subject<void>();

  constructor(
    private cdRef: ChangeDetectorRef,
    private dialogRef: DialogRef<ReferralsDialogComponent>,
  ) {}

  public setDialogData(data: ReferralsDialogData): void {
    this.referral = data.referral;
    this.statusTranslationsMap = data.translations;
    this.showStatus = data.showStatus;
  }

  public ngOnDestroy(): void {
    this.ngOnDestroy$.next();
    this.ngOnDestroy$.complete();
  }

  public close(): void {
    this.dialogRef.close();
  }
}
