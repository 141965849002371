import { NgTemplateOutlet, AsyncPipe } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { RouterLink } from '@angular/router';

import { select, Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { debounceTime, startWith, takeUntil, tap } from 'rxjs/operators';

import { filterBoolean } from '@recruitee/common';
import { I18nService, TranslatePipe } from '@recruitee/i18n';
import type {
  ReferralsPortalApplicationParams,
  ReferralsPortalApplication,
} from '@recruitee/referrals-types';
import { ReferralsPortalApplicationStatus } from '@recruitee/referrals-types';
import { SortDirection } from '@recruitee/search-types';
import {
  SelectOption,
  DropdownWidth,
  EmptyStateComponent,
  IconComponent,
  IconColorDirective,
  InputComponent,
  FormFieldComponent,
  DropdownHeaderComponent,
  DropdownComponent,
  ButtonDropdownIndicatorDirective,
  SpinnerComponent,
  ButtonComponent,
  DropdownItemComponent,
  OverlayOriginDirective,
  ConnectedOverlayDirective,
} from '@recruitee/user-interface';

import {
  ApplicationsActions,
  applicationsSelectors,
} from '../features/applications/data/store/applications.store';
import { programSelectors } from '../features/program/data/store/program.store';
import { ReferralsPortalProgram } from '../features/program/data/types';
import { ReferralsPortalAnalytics } from '../shared/referrals-portal.analytics';
import { ReferralsListComponent } from './referrals-list/referrals-list.component';

@Component({
  selector: 'rtr-referrals',
  templateUrl: './referrals.component.html',
  styleUrls: ['./referrals.component.less'],
  standalone: true,
  imports: [
    FormFieldComponent,
    InputComponent,
    ReactiveFormsModule,
    IconColorDirective,
    IconComponent,
    NgTemplateOutlet,
    ReferralsListComponent,
    EmptyStateComponent,
    ButtonComponent,
    RouterLink,
    SpinnerComponent,
    ButtonDropdownIndicatorDirective,
    OverlayOriginDirective,
    ConnectedOverlayDirective,
    DropdownComponent,
    DropdownHeaderComponent,
    DropdownItemComponent,
    AsyncPipe,
    TranslatePipe,
  ],
})
export class ReferralsComponent implements OnInit {
  public searchControl = new FormControl<string>('', { nonNullable: true });
  public applications: ReferralsPortalApplication[] = [];
  public referralStatusVisible: boolean;
  public rewardsVisible: boolean;
  public sortOrder$: BehaviorSubject<SortDirection> = new BehaviorSubject(SortDirection.Desc);
  public sortOptions$: BehaviorSubject<Array<SelectOption<string>>>;
  public sortBy$: BehaviorSubject<SelectOption<string>>;
  public isLoading: boolean = false;
  public filteredApplications: Record<string, ReferralsPortalApplication[]> = {};

  public readonly SortDirection: typeof SortDirection = SortDirection;
  public readonly ReferralStatus: typeof ReferralsPortalApplicationStatus =
    ReferralsPortalApplicationStatus;
  public readonly DropdownWidth: typeof DropdownWidth = DropdownWidth;

  private program$: Observable<ReferralsPortalProgram | null> = this.store.select(
    programSelectors.data,
  );

  private isLoading$: Observable<boolean> = this.store.select(applicationsSelectors.isPending());
  private ngOnDestroy$ = new Subject<void>();

  constructor(
    private store: Store,
    private cdRef: ChangeDetectorRef,
    private i18n: I18nService,
    private referralsPortalAnalytics: ReferralsPortalAnalytics,
  ) {}

  public ngAfterViewInit(): void {
    this.referralsPortalAnalytics.trackViewedReferralsPage();
  }

  // eslint-disable-next-line max-lines-per-function
  public ngOnInit(): void {
    this.program$
      .pipe(filterBoolean, takeUntil(this.ngOnDestroy$))
      .subscribe(({ referralStatusVisible, rewardsVisible }) => {
        this.referralStatusVisible = referralStatusVisible;
        this.rewardsVisible = rewardsVisible;
        this.setSortOptions(referralStatusVisible);
        this.cdRef.detectChanges();
      });

    this.sortBy$ = new BehaviorSubject(this.sortOptions$.value[0]);

    this.store
      .pipe(select(applicationsSelectors.mappedEntities()), takeUntil(this.ngOnDestroy$))
      .subscribe(entities => {
        this.applications = entities;
        if (this.referralStatusVisible) {
          Object.entries(ReferralsPortalApplicationStatus).forEach(([key, value]) => {
            this.filteredApplications[value] = entities.filter(app => app.referralStatus === value);
          });
        }
        this.cdRef.detectChanges();
      });

    this.isLoading$.pipe(takeUntil(this.ngOnDestroy$)).subscribe(loading => {
      this.isLoading = loading;
      this.cdRef.detectChanges();
    });

    const searchValue$ = this.searchControl.valueChanges.pipe(
      startWith(''),
      tap(_ => (this.isLoading = true)),
      debounceTime(150),
    );

    combineLatest(this.sortBy$, this.sortOrder$, searchValue$, this.sortOptions$)
      .pipe(takeUntil(this.ngOnDestroy$))
      .subscribe(([sortBy, sortOrder, searchValue]) => {
        this.loadApplicationsCollection({
          q: searchValue,
          sortOrder: sortOrder,
          sortBy: sortBy.value!,
        });
      });
  }

  public changeSortFilter(option: SelectOption<string>): void {
    const sortBy = this.sortBy$.value;
    const sortOrder = this.sortOrder$.value;

    if (sortBy.value === option.value)
      this.sortOrder$.next(
        sortOrder === SortDirection.Asc ? SortDirection.Desc : SortDirection.Asc,
      );

    this.sortBy$.next(option);
  }

  public loadApplicationsCollection(params: ReferralsPortalApplicationParams): void {
    this.store.dispatch(
      ApplicationsActions.fetchCollection({
        payload: { data: params },
      }),
    );
  }

  public setSortOptions(status: boolean): void {
    this.sortOptions$ = new BehaviorSubject([
      {
        value: status ? 'updated_at' : 'created_at',
        label: this.i18n.translate('rtr.referrals.sort_by.latest_activity'),
      },
      {
        value: 'candidate_name',
        label: this.i18n.translate('rtr.referrals.sort_by.candidate_name'),
      },
    ]);
  }

  public trackByValue(option: SelectOption<string>): string {
    return option.value!;
  }
}
