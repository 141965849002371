import { ChangeDetectorRef, Component, Input, ViewContainerRef } from '@angular/core';

import { collapse, trackByProp } from '@recruitee/common';
import { I18nService } from '@recruitee/i18n';
import type { ReferralsPortalApplication } from '@recruitee/referrals-types';
import { ReferralContext, ReferralsPortalApplicationStatus } from '@recruitee/referrals-types';
import {
  DialogService,
  DialogWidth,
  IconName,
  DividerComponent,
  IconColorDirective,
  BadgeComponent,
  HeadingComponent,
  RtTooltip,
  IconComponent,
} from '@recruitee/user-interface';

import { ReferralsCardComponent } from '../referrals-card/referrals-card.component';
import { ReferralsDialogComponent } from '../referrals-dialog/referrals-dialog.component';

@Component({
  selector: 'rtr-referrals-list',
  templateUrl: './referrals-list.component.html',
  styleUrls: ['./referrals-list.component.less'],
  animations: [collapse],
  standalone: true,
  imports: [
    HeadingComponent,
    BadgeComponent,
    IconColorDirective,
    IconComponent,
    RtTooltip,
    ReferralsCardComponent,
    DividerComponent,
  ],
})
export class ReferralsListComponent {
  @Input() public applications: ReferralsPortalApplication[];
  @Input() public showStatus: boolean = false;
  @Input() public title: string;
  @Input() public tooltipText: string;
  @Input() public showCounter: boolean = true;
  @Input() public collapseEnabled: boolean = true;
  @Input() public type: ReferralContext = ReferralContext.Referrals;
  @Input() public rewardsVisible: boolean = false;

  public isCollapsed: boolean = false;
  public statusTranslationsMap: Record<string, { status: string; icon: IconName }> = {
    [ReferralsPortalApplicationStatus.InReview]: {
      icon: 'clock',
      status: this.i18n.translate('rtr.referrals.statuses.referred'),
    },
    [ReferralsPortalApplicationStatus.ProcessComplete]: {
      icon: 'sidemenu-offers',
      status: this.i18n.translate('rtr.referrals.statuses.completed'),
    },
    [ReferralsPortalApplicationStatus.Claimed]: {
      icon: 'reward',
      status: this.i18n.translate('rtr.referrals.statuses.rewarded'),
    },
  };
  public trackById = trackByProp<{ id: number | string }>({ prop: 'id' });

  constructor(
    private dialogService: DialogService,
    private viewContainerRef: ViewContainerRef,
    private cdRef: ChangeDetectorRef,
    private i18n: I18nService,
  ) {}

  public collapseList(): void {
    this.isCollapsed = !this.isCollapsed;
    this.cdRef.detectChanges();
  }

  public openDialog(data: ReferralsPortalApplication): void {
    this.dialogService.openCustom(
      ReferralsDialogComponent,
      { width: DialogWidth.Small },
      { referral: data, translations: this.statusTranslationsMap, showStatus: this.showStatus },
      this.viewContainerRef,
    );
  }
}
