import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { filterBoolean } from '@recruitee/common';

import { AuthenticatedUserGuard } from '../auth/guards/authenticated-user.guard';
import { NotAuthenticatedUserGuard } from '../auth/guards/not-authenticated-user.guard';
import { MainViewComponent } from '../core/main-view/main-view.component';
import { TitleProvider } from '../core/services/title.service';
import { programSelectors } from '../features/program/data/store/program.store';
import { ReferralsComponent } from '../referrals/referrals.component';

export class RootPageTitles {
  @TitleProvider('rtr.page_title.profile')
  public static profile(store: Store): Observable<{ companyName: string }> {
    return RootPageTitles.provideCompanyName(store);
  }

  @TitleProvider('rtr.page_title.referrals')
  public static referrals(store: Store): Observable<{ companyName: string }> {
    return RootPageTitles.provideCompanyName(store);
  }

  public static provideCompanyName(store: Store): Observable<{ companyName: string }> {
    return store.select(programSelectors.data).pipe(
      filterBoolean,
      map(({ companyName }) => ({ companyName })),
    );
  }
}

export const ROUTES: Routes = [
  {
    path: 'error',
    loadChildren: () => import('../error-page/error-page.module').then(m => m.ErrorPageModule),
  },
  {
    path: 'auth',
    canActivate: [NotAuthenticatedUserGuard],
    loadChildren: () => import('../auth/auth.module').then(m => m.AuthModule),
  },
  {
    path: '',
    component: MainViewComponent,
    children: [
      {
        path: '',
        canActivate: [AuthenticatedUserGuard],
        children: [
          {
            path: '',
            loadChildren: () =>
              import('../offer-page/offer-page.module').then(m => m.OfferPageModule),
          },
          {
            path: 'profile',
            data: { pageTitle: RootPageTitles.profile },
            loadChildren: () => import('../profile/profile.module').then(m => m.ProfileModule),
          },
          {
            path: 'referrals',
            component: ReferralsComponent,
            data: { pageTitle: RootPageTitles.referrals },
          },
          {
            path: 'how-it-works',
            loadChildren: () =>
              import('../welcome-page/welcome-page.module').then(m => m.WelcomePageModule),
          },
        ],
      },
    ],
  },
  { path: '**', redirectTo: '/error/404' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(ROUTES, {
      initialNavigation: 'disabled',
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class RoutingModule {}
