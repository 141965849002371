<div layout-centered-y margin-bottom-small>
  <rt-heading variant="subheading dark large" margin-right-small-x>{{ title }}</rt-heading>
  @if (showCounter) {
    <rt-badge color="counter-default" [text]="applications.length" margin-right-small-x></rt-badge>
  }
  @if (tooltipText) {
    <rt-icon
      [rtTooltip]="tooltipText"
      [rtTooltipHasTextAlignCenter]="true"
      [rtTooltipMaxWidth]="220"
      name="help-outline"
      margin-right-small-x
    >
    </rt-icon>
  }
  @if (collapseEnabled) {
    <rt-icon
      (click)="collapseList()"
      [class.active]="!isCollapsed"
      class="collapse-icon"
      name="keyboard-arrow-down"
    ></rt-icon>
  }
</div>
<div class="applications-list" [@collapse]="isCollapsed ? 'collapsed' : 'normal'">
  @for (application of applications; track trackById($index, application)) {
    <rtr-referrals-card
      [application]="application"
      [showStatus]="showStatus"
      [rewardsVisible]="rewardsVisible"
      [statusTranslationsMap]="statusTranslationsMap"
      [type]="type"
      (referralSelect)="openDialog($event)"
    ></rtr-referrals-card>
  }
</div>

@if (isCollapsed) {
  <rt-divider></rt-divider>
}
