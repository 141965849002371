<div class="heading" padding-top-large padding-bottom-large>
  {{ 'rtr.homepage.referrals' | translate }}
</div>

@if (applications.length || searchControl.value || isLoading) {
  <div class="filters" layout layout-wrap layout-space-between margin-bottom-small-x>
    <rt-form-field class="search" margin-bottom-small>
      <rt-input
        [formControl]="searchControl"
        [placeholder]="'rtr.common.search' | translate"
        [hasClearButton]="true"
        (reset)="searchControl.setValue('')"
        variant="rounded"
      >
        <rt-icon prefix name="search"></rt-icon>
      </rt-input>
    </rt-form-field>
    <ng-container *ngTemplateOutlet="sortSection"></ng-container>
  </div>
}

@if (applications.length) {
  @if (!referralStatusVisible) {
    <rtr-referrals-list
      [applications]="applications"
      [showStatus]="false"
      [rewardsVisible]="rewardsVisible"
      [title]="'rtr.referrals.all_referrals' | translate"
      [collapseEnabled]="false"
    ></rtr-referrals-list>
  } @else {
    <rtr-referrals-list
      [applications]="filteredApplications[ReferralStatus.InReview]"
      [showStatus]="true"
      [rewardsVisible]="rewardsVisible"
      [title]="'rtr.referrals.in_progress' | translate"
      [tooltipText]="'rtr.referrals.tooltip.default' | translate"
      margin-bottom-large
    ></rtr-referrals-list>
    <rtr-referrals-list
      [applications]="filteredApplications[ReferralStatus.ProcessComplete]"
      [showStatus]="true"
      [rewardsVisible]="rewardsVisible"
      [title]="'rtr.referrals.completed' | translate"
      [tooltipText]="'rtr.referrals.tooltip.completed' | translate"
      margin-bottom-large
    ></rtr-referrals-list>
    <rtr-referrals-list
      [applications]="filteredApplications[ReferralStatus.Claimed]"
      [showStatus]="true"
      [rewardsVisible]="rewardsVisible"
      [title]="'rtr.referrals.successful_referrals' | translate"
      [tooltipText]="'rtr.referrals.tooltip.success' | translate"
    ></rtr-referrals-list>
  }
} @else {
  @if (searchControl.value && !isLoading) {
    <rt-empty-state
      [title]="'rtr.referrals.empty_state.filters.header' | translate"
      [desc]="'rtr.referrals.empty_state.filters.description' | translate"
      [minHeight]="true"
      variant="default v-centered"
      icon="favorite-border"
    >
      <rt-button variant="tertiary" (click)="searchControl.reset()">
        {{ 'rtr.referrals.empty_state.filters.clear' | translate }}
      </rt-button>
    </rt-empty-state>
  }
  @if (!searchControl.value && !isLoading) {
    <rt-empty-state
      [title]="'rtr.referrals.empty_state.header' | translate"
      [desc]="'rtr.referrals.empty_state.description' | translate"
      [minHeight]="true"
      variant="default v-centered"
      icon="favorite-border"
    >
      <rt-button variant="tertiary" routerLink="/">{{
        'rtr.referrals.see_open_jobs' | translate
      }}</rt-button>
    </rt-empty-state>
  }
  @if (isLoading) {
    <rt-spinner margin-top-large-x></rt-spinner>
  }
}

<ng-template #sortSection>
  <rt-button
    (click)="manageOpenDropdown.open = !manageOpenDropdown.open"
    rt-overlay-origin
    margin-left-small
    [dropdownOpened]="manageOpenDropdown.open"
    #manageOpenDropdownTrigger="rtOverlayOrigin"
    variant="tertiary"
  >
    <rt-icon
      [name]="sortOrder$.value === SortDirection.Asc ? 'sort-ascending' : 'sort-descending'"
    ></rt-icon>
    <span>{{ sortBy$.value?.label }}</span>
  </rt-button>
  <ng-template
    (backdropClick)="manageOpenDropdown.open = false"
    [origin]="$any(manageOpenDropdownTrigger)"
    #manageOpenDropdown="rtConnectedOverlay"
    rt-connected-overlay
    hasBackdrop
  >
    <rt-dropdown [width]="DropdownWidth.SmallX">
      <rt-dropdown-header [fullWidthTitle]="true">
        {{ 'rtr.referrals.sort_by.header' | translate }}
      </rt-dropdown-header>
      @for (option of sortOptions$ | async; track $any(trackByValue)($index, option)) {
        <rt-dropdown-item (click)="changeSortFilter(option)">
          {{ option.label }}
          @if (sortBy$.value.value === option.value) {
            <rt-icon
              suffix
              [name]="sortOrder$.value === SortDirection.Asc ? 'sort-ascending' : 'sort-descending'"
              colorToken="colorIconPrimaryDefault"
            ></rt-icon>
          }
        </rt-dropdown-item>
      }
    </rt-dropdown>
  </ng-template>
</ng-template>
