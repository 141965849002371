<rt-dialog-header variant="default">
  <div layout-centered-y>
    <rt-avatar
      [type]="referral.photoThumbUrl ? 'image' : referral.name ? 'initials' : 'icon'"
      [shape]="'oval'"
      [size]="'medium'"
      [imageSrc]="referral.photoThumbUrl"
      [fullName]="referral.name"
      [iconName]="$any(!referral.name && 'person')"
      [class.completed]="showStatus && referral.referralStatus === ReferralStatus.ProcessComplete"
      [class.success]="showStatus && referral.referralStatus === ReferralStatus.Claimed"
      margin-right-small
    ></rt-avatar>
    <div layout-vertical>
      <rt-heading variant="heading large-xx">
        {{ referral.name }}
      </rt-heading>
      <div layout layout-wrap>
        <span margin-right-small>{{ referral.offerTitle }}</span>
        <div layout>
          <rt-icon
            [name]="
              showStatus && referral.referralStatus
                ? statusTranslationsMap[referral.referralStatus].icon
                : statusTranslationsMap[ReferralStatus.InReview].icon
            "
            margin-right-small-x
          ></rt-icon>
          <span>
            {{
              showStatus && referral.referralStatus
                ? statusTranslationsMap[referral.referralStatus].status
                : statusTranslationsMap[ReferralStatus.InReview].status
            }}
            {{ (showStatus ? referral.updatedAt : referral.createdAt) | rtTimeAgo }}
          </span>
        </div>
      </div>
    </div>
  </div>
</rt-dialog-header>
<rt-dialog-body layout-vertical>
  <div layout layout-align-items-center>
    <rt-icon name="reward" margin-right-small-x></rt-icon>
    <rt-heading variant="heading uppercased">{{ 'rtr.common.reward' | translate }}</rt-heading>
  </div>
  <span>{{
    (showStatus && referral.referralStatus === ReferralStatus.Claimed
      ? 'rtr.referrals.dialog.success'
      : 'rtr.referrals.dialog.default'
    ) | translate
  }}</span>
  <rt-box variant="vertical" margin-top-small>
    <rt-heading variant="heading" margin-bottom-small-x>
      @if (referral.referralReward.photoThumbUrl) {
        <rt-avatar
          [type]="'image'"
          [size]="'small'"
          [shape]="'square'"
          [imageSrc]="referral.referralReward.photoThumbUrl"
          margin-right-small-x
        >
        </rt-avatar>
      }
      {{ referral.referralReward.name }}
    </rt-heading>
    <span [innerHTML]="referral.referralReward.bodyHtml"> </span>
  </rt-box>
</rt-dialog-body>
