import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';

import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AUTH_TOKEN, filterBoolean } from '@recruitee/common';

import { coreSelectors } from '../../features/core/data/store/core.store';

@Injectable({ providedIn: 'root' })
export class NotAuthenticatedUserGuard {
  constructor(
    @Inject(AUTH_TOKEN) private token: string,
    private router: Router,
    private store: Store,
  ) {}

  public canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> {
    return this.store.select(coreSelectors.data).pipe(
      filterBoolean,
      map(coreState => !this.token || coreState.isForeignAdmin || this.router.createUrlTree(['/'])),
    );
  }
}
