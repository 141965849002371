import { Injectable, Injector } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Event, NavigationEnd, Router } from '@angular/router';

import { Observable } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { I18nService } from '@recruitee/i18n';
import { getTextContentFromHTML } from '@recruitee/utils';

const TITLE_PROVIDER_METADATA_KEY = '__title_params__';

export function TitleProvider(translationKey: string): MethodDecorator {
  return (target: any, key: string) => {
    const params = Reflect.getMetadata('design:paramtypes', target, key);
    Reflect.defineMetadata(
      TITLE_PROVIDER_METADATA_KEY,
      { tokens: params, translationKey },
      target[key],
    );
  };
}

export type TitleProviderFunction = (...args: any) => object | Observable<object>;

@Injectable({ providedIn: 'root' })
export class TitleService {
  private navigationEnd$: Observable<Event> = this.router.events.pipe(
    filter(event => event instanceof NavigationEnd),
  );

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private title: Title,
    private injector: Injector,
    private i18nService: I18nService,
  ) {
    this.navigationEnd$.subscribe(() => this.onRouteChange());
  }

  private getTitleFunction(activatedRoute: ActivatedRoute): TitleProviderFunction {
    let route = activatedRoute.snapshot;
    let pageTitleFn = route.data && route.data.pageTitle;
    while (route.children.length) {
      route = route.children[0];
      pageTitleFn = route.data && route.data.pageTitle ? route.data.pageTitle : pageTitleFn;
    }

    return pageTitleFn;
  }

  private getMetadata(titleProvider: TitleProviderFunction): {
    tokens: any[];
    translationKey: string;
  } {
    return Reflect.getMetadata(TITLE_PROVIDER_METADATA_KEY, titleProvider);
  }

  private onRouteChange(): void {
    const pageTitleFn = this.getTitleFunction(this.route);
    if (!pageTitleFn) return;

    const { tokens, translationKey } = this.getMetadata(pageTitleFn);
    const deps = tokens.map(token => this.injector.get(token));
    const result = pageTitleFn(...deps);
    if (result instanceof Observable) {
      result
        .pipe(takeUntil(this.navigationEnd$))
        .subscribe(context => this.setTitle(translationKey, context));
    } else {
      this.setTitle(translationKey, result);
    }
  }

  private setTitle(key: string, context: object): void {
    const title = this.i18nService.translate(key, context);
    const parsedTitle = getTextContentFromHTML(title);
    this.title.setTitle(parsedTitle);
  }
}
